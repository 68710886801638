<footer id="footer">
    <div class="inner">
        <section>
            <h2>Kontakt</h2>
            <form method="post" action="#">
                <div class="fields">
                    <div class="field half">
                        <input type="text" name="name" id="name" placeholder="Imię" />
                    </div>
                    <div class="field half">
                        <input type="email" name="email" id="email" placeholder="Email" />
                    </div>
                    <div class="field">
                        <textarea name="message" id="message" placeholder="Wiadomość"></textarea>
                    </div>
                </div>
                <ul class="actions">
                    <li><input type="submit" value="Wyślij" class="primary" /></li>
                </ul>
            </form>
        </section>
        <section>
            <h2>Follow</h2>
            <ul class="icons">
                <li><a href="#" class="icon brands style2 fa-facebook-f"><span class="label">Facebook</span></a></li>
                <li><a href="#" class="icon brands style2 fa-twitter"><span class="label">Twitter</span></a></li>
                <li><a href="#" class="icon solid style2 fa-envelope"><span class="label">Email</span></a></li>
            </ul>
        </section>
        <ul class="copyright">
            <li>&copy; mSpadek. All rights reserved</li>
            <li><a routerLink="/privacy-policy" routerLinkActive="active">Polityka Prywatności</a></li>
            <li><a routerLink="/terms-of-service" routerLinkActive="active">Regulamin</a></li>
        </ul>
    </div>
</footer>
