<nav id="menu">
    <div class="inner">
        <h2>Menu</h2>
        <ul>
            <li><a routerLink="/" (click)="hideMenu()">Home</a></li>
<!--            <li><a routerLink="/privacy-policy" routerLinkActive="active" (click)="hideMenu()">Polityka Prywatności</a></li>-->
<!--            <li><a routerLink="/terms-of-service" routerLinkActive="active" (click)="hideMenu()">Regulamin</a></li>-->
            <li><a routerLink="/report/primary" routerLinkActive="active" (click)="hideMenu()">Raport Podstawowy</a></li>
            <li><a routerLink="/report/extended" routerLinkActive="active" (click)="hideMenu()">Raport Rozszerzony</a></li>
        </ul>
    </div>
    <a class="close" (click)="hideMenu()">Close</a>
</nav>
