import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { ActivatedRoute } from '@angular/router';
import { Order } from '../../../../models/order.model';
import { ResponseSingle } from '../../../../models/response.model';



@Component({
  selector: 'app-order-preview',
  templateUrl: './order-preview.component.html',
  styleUrls: ['./order-preview.component.scss']
})
export class OrderPreviewComponent implements OnInit, OnDestroy{

    public order!:Order;
    public paymentRedirectUri!:string | undefined;
    protected readonly window = window;

    constructor(
          private _orderService:OrderService,
          private _route:ActivatedRoute
      ) {
      }

      ngOnInit() {
        const orderId = this._route.snapshot.params['id'];
        this._orderService.getOrder(orderId).subscribe( data => {
         this.order = data.data;
         console.log(this.order);

       });
        this.paymentRedirectUri = this._orderService.orderResponse.getValue()?.data.redirectUri;



        // this._orderService.getOrder(orderId).subscribe(data:ResponseSingle<Order> => {
        //   // this.order = data.data;
        //   // console.log(this.order)
        // })
        // this.orderResponse = this._orderService.orderResponse.getValue();
        // this._orderService.orderRequest.asObservable().subscribe(
        // data => {
        //       console.log('data',data)
        //       if (data) {
        //             this.order = data;
        //         }
        //   }, error => {
        //       console.error('aaa', error)
        //   })
      }

    public goToUrl(url:string | undefined):void {

        if(!url) { return }
        this.window.open(url, '_self');
    }

      ngOnDestroy() {

        // this._orderService.orderRequest.unsubscribe();
      }
}
