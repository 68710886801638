<div id="main">

    <div class="inner" *ngIf="!report">
        <p>BRAK RAPORTU :( </p>
    </div>

    <div class="inner"  *ngIf="report" >


        <h1>Sytuacja osoby zmarłej w publicznych bazach danych.</h1>
        <div class="actions">
<!--            <input type="button" value="Pobierz raport" class="primary btn-download" (click)="generatePdf()" />-->
            <button type="button" class="primary btn-download" (click)="generatePdf()">Pobierz raport</button>
        </div>
        <div class="report">
            <div class="report-header">
                <div>
                    <strong>Osoba zmarła: {{ report.deceased.name }} {{report.deceased.surname  }}</strong><br/>
<!--                    <span>Imię ojca: Wiesław</span><br/>-->
<!--                    <span>Imię matki: Grażyna</span><br/>-->
                    <span>PESEL: {{ report.deceased.pesel || 'XXXXXXXXXX'}}</span><br/>
                    <span>NIP: {{ report.deceased.nip || 'XXXXXXXXXX' }} </span><br/>
                    <span>Data zgonu: {{ report.deceased.dateOfDeath || '2024-01-02' | date: 'longDate' }}</span>
                </div>
                <div>
                    <em>Wygenerowano na podstawie informacji z publicznych baz danych</em><br/>
                    <em>Data wygenerowania informacji z baz danych: {{ report.updatedAt | date:'d MMMM y, h:mm:ss'}}</em>
                </div>
            </div>
            <div class="table-wrapper">
                <table class="alt db-result">
                    <thead>
                    <tr>
                        <th>
                            <div class="question">
                                Baza danych
                            </div>
                            <div class="answer">
                                Wynik
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <div class="question">
                                <strong>Centralna Ewidencja Działalności Gospodarczej</strong><br/>
                                Czy osoba zmarła prowadziła dzialalność gospodarczą?<br/>
                                Prowadzenie działalności gospodarczej wiąże się z możliwością występowania aktywów, ale również z możliwością występowania zadłużenia w stosunku do administracji publicznej np. ZUS, US lub kontrahentów.
                            </div>
                            <div class="answer">
                                <strong>{{ report.report.ceidg?"TAK":"NIE" }}<span>, {{ report.report.ceidg?"TAK":"NIE" }} </span><span>WYSTĘPUJE</span></strong>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div class="question">
                                <strong>Krajowy Rejestr Sądowy</strong><br/>
                                Czy osoba zmarła posiadała prawa własności np. udziały lub akcje w spółkach?*<br/>
                                Posiadanie udziałów w spółkach jest aktywem, jednak jest to uzależnione od kondycji danej spółki.
                            </div>
                            <div class="answer">
                                <strong>{{ report.report.krs.propertyRights?"TAK":"NIE" }}<span>, {{ report.report.krs.propertyRights?"TAK":"NIE" }} </span><span>WYSTĘPUJE</span></strong>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div class="question">
                                <strong>Krajowy Rejestr Sądowy</strong><br/>
                                Czy osoba zmarła pełniła funkcje we władzach spółki np. jako członek zarządu spółki lub jej wspólnik?*<br/>
                                Pełnienie niektórych funkcji w spółce może się wiązać z odpowiedzialnością osobistą za zobowiązania spółki. Może to być potencjalne zródło długów osoby zmarłej.
                            </div>
                            <div class="answer">
                                <strong>{{ report.report.krs.companyAuthorities?"TAK":"NIE" }}<span>, {{ report.report.krs.companyAuthorities?"TAK":"NIE" }} </span><span>WYSTĘPUJE</span></strong>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div class="question">
                                <strong>Centralny Rejestr Beneficjentów Rzeczywistych</strong><br/>
                                Czy osoba zmarła jest beneficjentem spółki, trustu lub fundacji rodzinnej?<br/>
                                Występowanie osoby zmarłej w tym rejestrze może oznaczać posiadanie przez nią aktywów.
                            </div>
                            <div class="answer">
                                <strong>{{ report.report.crbr === true?"TAK":"NIE" }}<span>, {{ report.report.crbr === true?"TAK":"NIE" }} </span><span>WYSTĘPUJE</span></strong>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div class="question">
                                <strong>Wykaz Podatników Podatku VAT</strong><br/>
                                Czy osoba zmarła występuje w Wykazie podatników podatku VAT, czyli białej liście podatników VAT?<br/>
                                To jest informacja o posiadaniu przez osobę zmarłą statusu podatnika podatku VAT. Rodzaj tego statusu może oznaczać posiadanie aktywów albo zobowiązań np. wobec US.
                            </div>
                            <div class="answer">
                                <strong>{{ report.report.vatWl === true?"TAK":"NIE" }}<span>, {{ report.report.vatWl === true?"TAK":"NIE" }} </span><span>WYSTĘPUJE</span></strong>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div class="question">
                                <strong>Krajowy Rejestr Zadłużonych</strong><br/>
                                Czy osoba zmarła występuje w Krajowym Rejestrze Zadłużonych?<br/>
                                Występowanie osoby zmarłej w tym rejestrze może oznaczać posiadanie przez nią długów. To jest bardzo istotna informacja.
                            </div>
                            <div class="answer">
                                <strong>{{ report.report.krz === true?"TAK":"NIE" }}<span>, {{ report.report.krz === true?"TAK":"NIE" }} </span><span>WYSTĘPUJE</span></strong>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div class="question">
                                <strong>SUDOP</strong><br/>
                                Czy osoba zmarła otrzymała pomoc publiczną?<br/>
                                Pewne formy pomocy publicznej np. odroczenie zapłaty mandatu lub kary mogą być źrodłem długów osoby zmarłej, jeżeli nie zostały zapłacone.
                            </div>
                            <div class="answer">
                                <strong>{{ report.report.sudop === true?"TAK":"NIE" }}<span>, {{ report.report.sudop === true?"TAK":"NIE" }} </span><span>WYSTĘPUJE</span></strong>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div class="question">
                                <strong>Monitor Sądowy i Gospodarczy Ministerstwa Sprawiedliwości.</strong><br/>
                                Czy osoba zmarła występuje w Monitorze Sądowym i Gospodarczym MS?<br/>
                                Występowanie osoby zmarłej w tym rejestrze może oznaczać posiadanie przez nią długów. To jest bardzo istotna informacja.
                            </div>
                            <div class="answer">
                                <strong>{{ report.report.msig === true?"TAK":"NIE" }}<span>, {{ report.report.msig === true?"TAK":"NIE" }} </span><span>WYSTĘPUJE</span></strong>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="note">
                <em>
                    *Pamiętaj, że jeżeli osoba zmarła posiadała samodzielnie lub łącznie z innymi osobami mniej niż 10% udziałów spółki z ograniczoną
                    odpowiedzialnością lub nie posiadała wszystkich akcji spółki akcyjnej to takie informacje nie są publikowane i nie są widoczne w Dziale
                    1 KRS. Dlatego nawet jeżeli powyżej nie ukazuje się żadna spółka z ograniczoną odpowiedzialnością lub spółka akcyjna to nie oznacza
                    to, że osoba zmarła nie była udziałowcem lub akcjonariuszem żadnego przedsiębiorstwa.
                </em>
            </div>
            <div class="table-wrapper">
                <table class="alt">
                    <thead>
                    <tr>
                        <th>Definicje</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <strong>Beneficjent rzeczywisty</strong><br/>
                                Osoba, która sprawowała kontrolę nad danym podmiotem np. spółką lub miała decydujący wpływ na jego czynności lub działania, była udziałowcem lub akcjonariuszem mającym prawo własności więcej niż 25% ogólnej liczby udziałów lub akcji, dysponowała więcej niż 25% ogólnej liczby głosów w organie stanowiącym spółki lub sprawowała kontrolę nad osobą prawną lub osobami prawnymi w sposób opisany w art. 2 ust. 2 pkt 1 ustawy AML (Dz.U. 2022 poz. 593), sprawowała kontrolę nad osobą prawną przez posiadanie w stosunku do niej uprawnień wymienionych w art. 3 ust. 1 pkt 37 ustawy z dnia 29 września 1994 r. o rachunkowości (Dz. U. z 2019 r. poz. 351), była fundatorem w rozumieniu ustawy z dnia 26 stycznia 2023 r. o fundacji rodzinnej (Dz. U. poz. 326) albo beneficjentem w rozumieniu tej ustawy, była założycielem trustu, jego beneficjentem lub sprawowała kontrolę nad trustem.
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <strong>Aktywa</strong><br/>
                                Zasoby majątkowe o określonej wartości lub inaczej majątek np. maszyny, pojazdy, nieruchomości, wierzytelności, środki na rachunkach bankowych, udziały w spółkach, itp.
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <strong>Pasywa</strong><br/>
                                Zasoby zmniejszające majątek lub takie, z których finansowany jest majątek np. kredyty, pożyczki oraz długi i zobowiązaniach wobec podmiotów, którym osoba zmarła w jakiś sposób była winna pieniądze.
                            </td>
                        </tr>

<!--                    <tr *ngFor="let x of definitions">-->
<!--                        <td>-->
<!--                            <strong>{{x.title}}</strong><br/>-->
<!--                            {{x.desc}}-->
<!--                        </td>-->
<!--                    </tr>-->

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
