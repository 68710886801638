import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrderComponent } from './modules/orders/components/order/order.component';
import { OrderRequestComponent } from './modules/orders/components/order-request/order-request.component';
import { OrderPreviewComponent } from './modules/orders/components/order-preview/order-preview.component';
import { ReportComponent } from './modules/reports/components/report/report.component';
import { MainComponent } from './modules/core/components/main/main.component';
import { ReportExtendedComponent } from './modules/reports/components/report-extended/report-extended.component';
import { ReportPrimaryComponent } from './modules/reports/components/report-primary/report-primary.component';
import { ProductTypes } from './models/product.model';
import {
  OrderPaymentStatusComponent
} from './modules/orders/components/order-payment-status/order-payment-status.component';

const routes: Routes = [
  {
    path: '',
    title: 'mSpadek',
    component: MainComponent
  },
  {
    path: 'order',
    component: OrderComponent,
    children: [
      {
        path: 'primary',
        component: OrderRequestComponent,
        data :{
          productType: ProductTypes.PRIMARY
        }
      },
      {
        path: 'extended',
        component: OrderRequestComponent,
        data :{
          productType: ProductTypes.EXTENDED
        }
      },
      {
        path: 'preview/:id',
        component: OrderPreviewComponent
      },
      {
        path: ':id',
        component: OrderPaymentStatusComponent
      }
    ]
  },
  {
    path: 'report',
    component: ReportComponent,
    children: [
      {
        path: 'primary',
        component: ReportPrimaryComponent
      },
      {
        path: 'primary/:id',
        component: ReportPrimaryComponent
      },
      {
        path:'extended',
        component: ReportExtendedComponent
      },
      {
        path: ':id',
        component: ReportComponent
      },


    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {initialNavigation: 'enabledBlocking'}
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
