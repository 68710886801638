import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { OrderComponent } from './components/order/order.component';
import { OrderRequestComponent } from './components/order-request/order-request.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderPreviewComponent } from './components/order-preview/order-preview.component';
import { SharedModule } from '../shared/shared.module';
import { OrderPaymentStatusComponent } from './components/order-payment-status/order-payment-status.component';



@NgModule({
  declarations: [
    OrderComponent,
    OrderRequestComponent,
    OrderPreviewComponent,
    OrderPaymentStatusComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule]

})
export class OrdersModule { }
