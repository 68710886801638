<div id="main">

    <div class="inner">
        <header>
<!--            <div style="overflow: hidden; width: 1450px; height: 650px;">-->
<!--                <div class="splash">-->
<!--                    &lt;!&ndash;        <img src="assets/images/splash2.jpeg"/>&ndash;&gt;-->
<!--                </div>-->
<!--            </div>-->


            <h1 class="align-center">mSpadek miejsce, w którym prosto i szybko dowiesz się więcej o sprawach spadkowych.</h1>

            <p class="align-center">
                Nie wiesz czy przyjąć, czy odrzucić spadek? Chcesz się dowiedzieć czy po kimś dziedziczysz?
                Potrzebujesz informacji, czy spadkodawca pozostawił jakieś aktywa lub długi np. w stosunku do Skarbu Państwa?
                Najpewniejszym, ale najdroższym i najbardziej czasochłonnym rozwiązaniem jest wykonanie Spisu inwentarza.
                Nasze raporty spadkowe pomogą Ci w podjęciu decyzji.
            </p>
        </header>



        <section class="products">

            <article class="product product--free">
                <div (click)="goToUrl('http://ktopokim.pl')">
                    <h2 class="product__title">Kto dziedziczy</h2>
                    <div  class="product__price">
                        <h1 class="product__amount">0 zł</h1><span> / test</span>
                    </div>

                    <div class="content">
                        <p>
                            Szybko sprawdzisz:
                        </p>
                        <ul>
                            <li>kto jest twoim spadkobiercą</li>
                            <li>tylko w 2 minuty</li>
                            <li>bez rejestracji</li>
                            <li>zupełnie za darmo</li>
                        </ul>

                    </div>
                </div>

                <div class="v-spacer">
                    <button class="primary product__button">Zrób test</button>
                </div>
            </article>

            <article class="product product--primary">
                <div routerLink="/order/primary">
                    <h2 class="product__title">Podstawowy</h2>
                    <div  class="product__price">
                        <h1 class="product__amount">1 zł</h1><span> / raport</span>
                    </div>


                    <div class="content">
                        <p>
                            Szybko sprawdzisz czy osoba zmarła:
                        </p>
                        <ul>
                            <li>występuje w wybranych publicznych bazach danych</li>
                            <li>występuje w wybranych prywatnych bazach danych</li>
                            <li>pozostawiła jakieś potencjalne aktywa lub długi</li>
                        </ul>
                        <p>W raporcie otrzymasz opis znaczenia baz danych</p>
                    </div>
                </div>
                <div class="v-spacer">
                    <a class="product__link" routerLink="report/primary">Przykładowy raport</a>
                    <button class="primary product__button" (click)="goToOrderForm('PRIMARY')" >Zamów raport</button>

                </div>
            </article>

            <article class="product product--extended">
                <div  routerLink="/order/extended">
                    <h2 class="product__title">Rozszerzony</h2>
                    <div  class="product__price">
                        <h1 class="product__amount">49 zł</h1><span> / raport</span>
                    </div>
                    <div class="content">

                        <p><>Szybko sprawdzisz czy osoba zmarła:</p>
                        <ul>
                            <li>występuje w wybranych publicznych i prywatnych bazach danych</li>
                            <li>pozostawiła jakieś potencjalne aktywa lub długi</li>
                        </ul>
                        <p>W raporcie otrzymasz:</p>
                        <ul>
                            <li>szczegółowe informacje o osobie zmarłej z wybranych baz danych</li>
                            <li>szczegółowe dane o potencjalnych aktywach lub długach</li>
                            <li>oficjalne dokumenty z wybranych baz danych</li>
                            <li>dokument zredagowany w jasnej i przystępnej formie</li>
                        </ul>


                    </div>
                </div>
                <div class="v-spacer">
                    <a class="product__link" routerLink="report/extended">Przykładowy raport</a>
                    <button disabled class="primary product__button">Wkrótce</button>
                </div>
            </article>
        </section>
    </div>
</div>
