import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { TermsComponent } from './components/terms/terms.component';
import { MainComponent } from './components/main/main.component';


@NgModule({ declarations: [
        MainComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        TermsComponent
    ],
    exports: [FooterComponent, HeaderComponent, MenuComponent, MainComponent], imports: [CommonModule, RouterModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class CoreModule { }
