import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit{
  constructor(
      private _route:ActivatedRoute,
      private _orderService: OrderService,
  ) {
  }

  ngOnInit() {
    // const id:string | null  = this._route.snapshot.paramMap.get('id');
    // if(id) {
    //   this.id = id;
    //   this._getOrder(id);
    // }

  }

  // private _getOrder(id:string) {
  //   this._orderService.getOrder(id).subscribe( (data:OrderResponse)=> {
  //     this.order = data.interest;
  //
  //   })
  //
  // }
}
