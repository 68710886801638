<div id="main">
    <div class="inner">
        <h2>Zamówienie na</h2>
        <h1 *ngIf="productType==='PRIMARY'">Podstawowy raport spdakowy</h1>
        <h1 *ngIf="productType==='EXTENDED'">Podstawowy raport spdakowy</h1>
        <p>Uzupłenij poniże dane osoby zmarłej oraz osoby zamawiającej</p>


        <form autocomplete="off" [formGroup]="orderForm" (submit)="onSubmit()" (change)="onFormChange()">
          <div class="row">
              <section class="deceased col-lg-12">
                  <h3>Dane zmarłego</h3>
              </section>
          </div>

            <div class="row">
                <section class="deceased col-lg-6">
                    <div class="form-group">
                        <label for="name">Imię zmarłego</label>
                        <input class="form-control" type="text" id="name" formControlName="name">
                    </div>
                </section>

                <section class="deceased col-lg-6">
                    <div class="form-group">
                        <label for="surname">Nazwisko zmarłego</label>
                        <input class="form-control" type="text" id="surname" formControlName="surname">
                    </div>
                </section>
            </div>

            <div class="row">
                <section class="deceased col-lg-6">
                    <div class="form-group">
                        <label for="pesel">PESEL zmarłego</label>
                        <input class="form-control" type="text" id="pesel" formControlName="pesel">
                    </div>
                </section>

                <section class="deceased col-lg-6">
                    <div class="form-group">
                        <label for="nip">NIP zmarłego</label>
                        <input class="form-control" type="text" id="nip" formControlName="nip">
                    </div>
                </section>
            </div>


            <div class="row">
                <section class="deceased col-lg-6">
                    <div class="form-group">
                        <label for="nip">Data śmierci zmarłego</label>
                        <input class="form-control" type="date" id="dateOfDeath" formControlName="dateOfDeath">
                    </div>
                </section>


            </div>


            <div class="row">
                <section class="deceased col-lg-6">
                    <h3>Dane zamawiającego</h3>
                    <div class="form-group">
                        <label for="clientName">Imię</label>
                        <input class="form-control" type="text" id="clientName" formControlName="clientName">
                    </div>

                    <div class="form-group">
                        <label for="clientSurname">Nazwisko</label>
                        <input class="form-control" type="text" id="clientSurname" formControlName="clientSurname">
                    </div>

                    <div class="form-group">
                        <label for="clientEmail">Adres-email</label>
                        <input class="form-control" type="email" id="clientEmail" formControlName="clientEmail">
                    </div>

                    <div class="form-group">
                        <label for="clientEmailConfirm">Powtórz adres-email</label>
                        <input class="form-control" type="email" id="clientEmailConfirm" formControlName="clientEmailConfirm">
                    </div>
                </section>

                <section class="deceased col-lg-6">
                    <h3>Dane do płatności</h3>

                    <div class="form-group">
                        <label for="clientName">Imię</label>
                        <input class="form-control" type="text" id="payerName" formControlName="payerName">
                    </div>

                    <div class="form-group">
                        <label for="clientSurname">Nazwisko</label>
                        <input class="form-control" type="text" id="payerSurname" formControlName="payerSurname">
                    </div>

                    <div class="form-group">
                        <label for="clientEmail">Adres (ulica numerbudynku/mieszkania)</label>
                        <input class="form-control" type="text" id="payerStreet" formControlName="payerStreet">
                    </div>

                    <div class="form-group">
                        <label for="clientEmailConfirm">Kod pocztowy (format XX-XXX)</label>
                        <input class="form-control" type="text" id="payerZipCode" formControlName="payerZipCode">
                    </div>

                    <div class="form-group">
                        <label for="clientEmailConfirm">Miasto</label>
                        <input class="form-control" type="text" id="payerCity" formControlName="payerCity">
                    </div>


                </section>
            </div>



            <div class="row">
                <div class="col-lg-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" formControlName="acceptAll" (change)="onAcceptAllChanged($event)" id="acceptAll">
                        <label class="form-check-label checkbox-small" for="acceptAll">
                            Akceptuję wszystkie poniższe zgody i zaznaczam wszystkie oświadczenia.
                        </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" formControlName="termsAndConditions" id="termsAndConditions">
                        <label class="form-check-label checkbox-small" for="termsAndConditions">
                            Oświadczam, że zapoznałem się i akceptuje Regulamin <a href="#" target="_blank">mSpadek.pl</a> oraz potwierdzam znajomość Polityki Prywatności.
                        </label>
                    </div>


                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" formControlName="verificationOfTheDeceased" id="verificationOfTheDeceased">
                        <label class="form-check-label checkbox-small" for="verificationOfTheDeceased">Oświadczam, że osoba weryfikowana nie żyje. Weryfikuję osobę zmarłą.
                        </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" formControlName="consentToElectronicCommunications" id="consentToElectronicCommunications">
                        <label class="form-check-label checkbox-small" for="consentToElectronicCommunications">Wyrażam zgodę na kontaktowanie się ze mną przez mSpadek.pl za pomocą środków komunikacji elektronicznej przy użyciu telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących, w tym poprzez e-mail, telefon lub SMS/MMS oraz komunikatory internetowe.
                        </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" formControlName="marketingAndCommercialConsent" id="marketingAndCommercialConsent">
                        <label class="form-check-label checkbox-small" for="marketingAndCommercialConsent">Wyrażam zgodę na otrzymywanie od mSpadek.pl za pomocą środków komunikacji elektronicznej na wskazany przeze mnie adres e-mail lub numer telefonu informacji handlowych i marketingowych dotyczących usług i produktów oferowanych przez mSpadek.pl.
                        </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" formControlName="immediateExecution" id="immediateExecution">
                        <label class="form-check-label checkbox-small" for="immediateExecution">Proszę o rozpoczęcie wykonywania usługi przed upływem terminu do odstąpienia od umowy zawartej na odległość i dostarczenie treści cyfrowych. Rozumiem, że oznacza to utratę prawa do odstąpienia od umowy, jeśli usługa została w całości wykonana lub udostępnianie treści cyfrowych rozpoczęło się i będę zobowiązany(a) do zapłaty za usługi świadczone na moją rzecz.
                        </label>
                    </div>
                </div>
            </div>

            <div class="row action">
                <div class="col-lg-12 text-center">
                    <button [disabled]="orderForm.invalid" type="submit" class="primary">ZAMÓW RAPORT</button>
                </div>
            </div>
        </form>

        <ng-template #confirmImmediateExecution let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Jesteś pewny?</h4>
    <!--            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>-->
            </div>
            <div class="modal-body">
                <p>Jeżeli nie wyrazisz zgody na na rozpoczęcie wykonaywania usługi, <strong>raport dostarczymy dopiero za 14 dni</strong>. </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" (click)="c(true)">Jestem pewna/y</button>
                <button type="button" class="btn btn-primary" (click)="c(false)">Zazcznę co trzeba</button>
            </div>
        </ng-template>
    </div>
</div>



