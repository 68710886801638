import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReportComponent } from './components/report/report.component';
import { ReportPrimaryComponent } from './components/report-primary/report-primary.component';
import { ReportExtendedComponent } from './components/report-extended/report-extended.component';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    ReportComponent,
    ReportPrimaryComponent,
    ReportExtendedComponent

  ], imports: [CommonModule, SharedModule],
  providers: [ DatePipe ]
})
export class ReportsModule { }
