<main id="main">
    <div class="inner">
        <div *ngIf="!order">
            <p>nie ma orderu!</p>
        </div>

        <div *ngIf="order">
            <h2>Potwierdzenie zamówienia na</h2>
            <h1 *ngIf="order.details.product ==='PRIMARY'">Podstawowy raport spdakowy</h1>
            <h1 *ngIf="order.details.product==='EXTENDED'">Podstawowy raport spdakowy</h1>
            <p>Zweryfikuj poniże informację i opłać zamówienie.</p>

            <div class="row order-preview-details">
                <section class="col-lg-4">
                    <h3>Zmarły</h3>
                    <p><strong>{{order.deceased.name}} {{order.deceased.surname}}</strong></p>
                    <p>{{order.deceased.pesel}} | {{order.deceased.nip}} </p>
                </section>

                <section class="col-lg-4">
                    <h3>Zamawiający</h3>
                    <p><strong>{{order.client.name}} {{order.client.surname}}</strong></p>
                    <p>{{order.client.email}}</p>
                </section>

                <section class="col-lg-4">
                    <h3>Płatnik</h3>
                    <p><strong>{{order.payer.name || 'John' }} {{order.payer.surname || 'Doe'}}</strong></p>
                    <p>{{order.payer.street || 'Testowa 12'}}</p>
                    <p>{{order.payer.zipCode || '00-000'}} {{order.payer.city || 'Warszawa'}}</p>
                </section>
            </div>



        </div>

        <div class="row action">
            <div class="col-lg-12 text-center">
                <button  [disabled]="!paymentRedirectUri" class="primary product__button" (click)="goToUrl(paymentRedirectUri)" >OPŁAĆ ZAMÓWIENIE</button>

            </div>
        </div>



    </div>
</main>
