import { Pipe, PipeTransform } from '@angular/core';
import { PaymentStatuses } from '../../../models/order.model';

@Pipe({
  name: 'paymentStatus'
})
export class PaymentStatusPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let result = '';
    switch(value) {
      case PaymentStatuses.CANCELED:
        result = 'Anulowane';
        break;
      case PaymentStatuses.COMPLETED:
        result = 'Opłacone';
        break;
      case PaymentStatuses.FAILED:
        result = 'Błędne';
        break;
      case PaymentStatuses.SUBMITTED:
        result = 'W realizacji';
        break;
      case PaymentStatuses.CREATED:
      default:
        result = 'Utworzone';
        break;
    }
    return result;
  }

}
