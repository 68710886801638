import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentStatusPipe } from './pipes/payment-status.pipe';
import { RouterModule } from '@angular/router';



@NgModule({
    declarations: [PaymentStatusPipe],
    exports: [PaymentStatusPipe,RouterModule],
    imports: [CommonModule]
})
export class SharedModule { }
