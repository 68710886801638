import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html' ,
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

    @Output() burgerClick = new EventEmitter<void>();

    // showMenu() {
    //     // console.log('aaa')
    //     this.burgerClick.emit();
    // }
}
