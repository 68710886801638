import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../services/report.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit{
  constructor(
      private _reportService: ReportService,
      private _route:ActivatedRoute,
      private _router: Router
  ) {


  }


  ngOnInit() {
    const reportId = this._route.snapshot.params['id'];
    if(!reportId) return;

    this._router.navigate(['/report', 'primary',reportId])
    // console.log(reportId);
    // //TODO change for getReportStatus after API change
    // this._reportService.getReport(reportId).subscribe((data)=> {
    //   this._router.navigate(['/report', data.data.type.toLowerCase(),reportId])
    // })

  }
}
