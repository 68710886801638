import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ProductType } from '../../../../models/product.model';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {

  protected readonly window = window;

  constructor(
      private _router: Router
  ) {
  }

  public goToUrl(url:string):void {
    this.window.open(url, '_blank');
  }

  public goToOrderForm(productType:ProductType): void {
    this._router.navigate(['order', productType.toLowerCase()])
  }
}
