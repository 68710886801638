import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mspadek';
  menuVisible = false;
  constructor(
      @Inject(DOCUMENT) private document: any
  ) {
  }

  menuShow() {
    this.document.body.classList.add('is-menu-visible');
  }

  menuHide() {
    this.document.body.classList.remove('is-menu-visible');
  }
}
