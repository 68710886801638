<div id="main">
    <div class="inner">
            <h1>Potwierdzenie zamówienia</h1>

            <header class="header">
                <div>
                    <p><strong>Numer zamówienia: </strong> {{ order?.details?.id }}</p>
                    <p><strong>Data zamówienia: </strong> {{order?.details?.dateCreated | date: 'medium'}}</p>
                </div>
                <div>
                    <p><strong>Status płatności: </strong>
                        <span
                                *ngIf="order?.payment?.status === 'COMPLETED'"
                                class="badge badge-success">{{order?.payment?.status | paymentStatus}}</span>

                        <span
                                *ngIf="order?.payment?.status === 'FAILED'
                                || order?.payment?.status === 'CANCELED'"
                                class="badge badge-warning">{{order?.payment?.status | paymentStatus}}</span>

                        <span
                                *ngIf="order?.payment?.status !== 'FAILED'
                                && order?.payment?.status !== 'CANCELED'
                                && order?.payment?.status !== 'COMPLETED'"
                                class="badge badge-danger">{{order?.payment?.status | paymentStatus}}</span>
                    </p>
                    <p><strong>Id płatności: </strong> {{ order?.payment?.externalPaymentId}}</p>
                </div>
            </header>
            <main>
                <section>
                    <h2>Produkty</h2>
                    <table class="products">
                        <thead>
                            <tr>
                                <th><h3 class="products__header">Produkt</h3></th>
                                <th><h3 class="products__header">Cena</h3></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>
                                    <div class="products__info">
                                        <strong>Raport Podstawowy</strong>
                                        <p>
                                            {{order?.deceased?.name}} {{order?.deceased?.surname}}
                                            | {{order?.deceased?.pesel}}
                                        </p>
                                    </div>

                                   </th>
                                <th><p>{{ 1 | currency}}</p></th>
                            </tr>
                        </tbody>
                    </table>
                </section>

                <section class="delivery">
                    <h2>Dane dostawy</h2>
                    <p><strong>{{order?.client?.name}} {{order?.client?.surname}}</strong></p>
                    <p>{{order?.client?.email}}</p>

                </section>

                <section class="report">
                    <h2>Raport</h2>
<!--                    <p>Kiedy raport zostanie stowrzony otrzymasz do niego link na wskazany adres email. Można go znleść również <strong> <a [routerLink]="['/report', (order?.details?.product || 'primary' ).toLowerCase(), orderId]">tutaj</a></strong>.</p>-->

                    <p>Kiedy raport zostanie stowrzony otrzymasz do niego link na wskazany adres email. Można go znleść również <strong> <a [routerLink]="['/report', orderId]">tutaj</a></strong>.</p>

                </section>

            </main>
    </div>
</div>


