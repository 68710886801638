<header id="header">
    <div class="inner">
        <a href="/" class="logo">
            <span class="symbol"><img src="/assets/images/logo.svg" alt="mSpadek" style="width: 10em;" /></span>
        </a>
<!--        <nav>-->
<!--            <ul>-->
<!--                <li><a class="menu-icon" (click)="showMenu()">Menu</a></li>-->
<!--            </ul>-->
<!--        </nav>-->
    </div>
</header>
